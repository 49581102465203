<script>
  let src_logo = "/images/eci_logo_lg.png";
  let alt_logo = "ENOS City Industries, LLC - eci.gg";
</script>

<style>#logo {
  padding: 5px 0 5px 5px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Db21wb25lbnRzL1NpbXBsZU5hdmJhci5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxzQkFBc0I7QUFDeEIiLCJmaWxlIjoic3JjL0NvbXBvbmVudHMvU2ltcGxlTmF2YmFyLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAjbG9nbyB7XG4gICAgcGFkZGluZzogNXB4IDAgNXB4IDVweDtcbiAgfVxuIl19 */</style>

<header class="bg-transparent">
  <div class="flex items-center justify-center sm:justify-between px-4 py-3">
    <div>
      <img id="logo" class="h-32" src={src_logo} alt={alt_logo} />
    </div>
  </div>
</header>
