<script>
  export let sectionTitle;
</script>

<style>hr {
  margin: 1px 30% 20px;
  border-color: whitesmoke;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Db21wb25lbnRzL1NlY3Rpb24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0Usb0JBQW9CO0VBQ3BCLHdCQUF3QjtBQUMxQiIsImZpbGUiOiJzcmMvQ29tcG9uZW50cy9TZWN0aW9uLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICBociB7XG4gICAgbWFyZ2luOiAxcHggMzAlIDIwcHg7XG4gICAgYm9yZGVyLWNvbG9yOiB3aGl0ZXNtb2tlO1xuICB9XG4iXX0= */</style>

<div class="flex mx-5">
  <img class="" src="/images/terminal-white.svg" alt="logo for section" />
  <h2 class="text-gray-200">
    <strong>{sectionTitle}</strong>
  </h2>
</div>
<hr />
